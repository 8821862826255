import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";

// Register necessary Chart.js components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip
);

const TransactionC2BStatsView = () => {
  const [response, setResponse] = useState([]); // State for API response
  const [loading, setLoading] = useState(true); // State to handle loading

  // Get propertyId from localStorage
  const propertyId = localStorage.getItem("selectedPropertyId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/landlordviewc2byearlymonthly",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include Bearer token in the headers
            },
            params: {
              propertyid: propertyId, // Pass propertyid as a parameter
            },
          }
        );
        console.log(data); // Handle response data
        setResponse(data.response); // Assuming you update state here
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [propertyId]);

  if (loading) {
    return <div>Loading...</div>; // Display loading indicator
  }

  // Prepare data for the line chart
  const chartData = {
    labels: response.map((data) => data.month), // X-axis labels
    datasets: [
      {
        label: "Total Amount (KSH)",
        data: response.map((data) => parseFloat(data.total_amount)), // Y-axis values
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Line fill
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        borderWidth: 2,
        tension: 0.4, // Smooth curve
      },
    ],
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: true, position: "top" },
    },
    scales: {
      x: {
        title: { display: true, text: "Month" },
      },
      y: {
        title: { display: true, text: "Total Amount (KSH)" },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Table */}
      <h2>Incomes Table</h2>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          marginBottom: "30px",
          textAlign: "left",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>Month</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              No of payments
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Amount (KSH) - commission not deducted
            </th>
          </tr>
        </thead>
        <tbody>
          {response.map((data, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {data.month}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {data.count}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {data.total_amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Line Graph */}
      <h2>Incomes Line Graph</h2>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default TransactionC2BStatsView;
