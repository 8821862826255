import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";

import "react-pro-sidebar/dist/css/styles.css"; // Import the sidebar styles
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronRight,
  faChartBar,
  faComment,
  faWrench,
  faDoorOpen,
  faFileInvoice,
  faMoneyBillWave,
  faPiggyBank,
  faFolderOpen,
  faExchangeAlt,
  faReceipt,
  faHandHoldingUsd,
  faChartLine,
  faCalendarCheck,
  faHome,
  faBuilding,
  faPenToSquare,
  faHouseUser,
  faFileSignature,
  faConciergeBell,
  faWater,
  faUserTie,
  faUsers,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";

const PropertyPage = () => {
  const { selectedPropertyId } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [balance, setBalance] = useState(null);
  const token = localStorage.getItem("token");
  const PropertyId = localStorage.getItem("selectedPropertyId");
  const [collapsed, setCollapsed] = useState(false); // Manage sidebar state

  useEffect(() => {
    // Fetch property details
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${PropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setProperty(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching property:", error);
        });
    }
  }, []);

  const handleUpdateProperty = () => {
    // Navigate to update property page
    navigate(`/landlord/updateproperty`);
  };

  const handleWithdrawals = () => {
    // Navigate to withdrawals page
    navigate(`/landlord/withdrawals`);
  };

  const handleDeposits = () => {
    // Navigate to withdrawals page
    navigate(`/landlord/deposits`);
  };

  const handleComments = () => {
    // Navigate to comments page
    navigate(`/landlord/comments`);
  };

  const handleVacating = () => {
    // Navigate to vacating page
    navigate(`/landlord/vacating`);
  };

  const handleCaretaker = () => {
    // Navigate to caretaker page
    navigate(`/landlord/caretaker`);
  };

  const handleMaintenance = () => {
    // Navigate to maintenance page
    navigate(`/landlord/maintenance`);
  };

  const handleReservations = () => {
    // Navigate to reservations page
    navigate(`/landlord/reservations`);
  };

  const handlePropertyKYC = () => {
    // Navigate to property KYC page
    navigate(`/landlord/propertykyc`);
  };

  const handleWaterUnits = () => {
    // Navigate to water units page
    navigate(`/landlord/waterunits`);
  };

  const handleAmenities = () => {
    // Navigate to amenities page
    navigate(`/landlord/amenities`);
  };

  const handleRentals = () => {
    // Navigate to rentals page
    navigate(`/landlord/rentals`);
  };
  const handlePaymentRecords = () => {
    // Navigate to rentals page
    navigate(`/landlord/recordsview`);
  };

  const handleTransactionsView = () => {
    // Navigate to rentals page
    navigate(`/landlord/transactionsview`);
  };

  const handleSettlementsView = () => {
    // Navigate to rentals page
    navigate(`/landlord/settlement`);
  };

  const handleAccountsView = () => {
    // Navigate to rentals page
    navigate(`/landlord/account`);
  };
  const handleStatistics = () => {
    // Navigate to rentals page
    navigate(`/landlord/statistics`);
  };
  const handleTrashPrice = () => {
    // Navigate to rentals page
    navigate(`/landlord/checktrashprice`);
  };

  return (
    <AuthGuard>
      <div className="flex min-h-screen">
        {/* Sidebar */}
        <ProSidebar
          collapsed={collapsed}
          className="flex min-h-screen h-screen"
        >
          <SidebarHeader>
            <div className="flex justify-between items-center p-4">
              <h2
                className={`text-xl font-semibold text-white ${
                  collapsed ? "hidden" : "block"
                }`}
              >
                Admin Dashboard
              </h2>
              <button
                onClick={() => setCollapsed(!collapsed)}
                className="text-white bg-transparent border-none cursor-pointer p-2"
              >
                {collapsed ? (
                  <FontAwesomeIcon icon={faChevronRight} size="lg" />
                ) : (
                  <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                )}
              </button>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="circle">
              <MenuItem
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={() => navigate("/Landlord/home")}
              >
                Home
              </MenuItem>
              <SubMenu
                title="Property"
                icon={<FontAwesomeIcon icon={faBuilding} />}
              >
                <MenuItem
                  icon={<FontAwesomeIcon icon={faPenToSquare} />}
                  onClick={handleUpdateProperty}
                >
                  Update Property
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faHouseUser} />}
                  onClick={handleRentals}
                >
                  Rentals
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faFileSignature} />}
                  onClick={handlePropertyKYC}
                >
                  PropertyKYC
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faConciergeBell} />}
                  onClick={handleAmenities}
                >
                  Amenities
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faWater} />}
                  onClick={handleWaterUnits}
                >
                  Water Units
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  onClick={handleTrashPrice}
                >
                  Trash Collection Fee
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faUserTie} />}
                  onClick={handleCaretaker}
                >
                  Caretaker
                </MenuItem>
              </SubMenu>
              <MenuItem
                icon={<FontAwesomeIcon icon={faCalendarCheck} />}
                onClick={handleReservations}
              >
                Reservations
              </MenuItem>
              <SubMenu
                title="Records"
                icon={<FontAwesomeIcon icon={faFolderOpen} />}
              >
                <MenuItem
                  icon={<FontAwesomeIcon icon={faExchangeAlt} />}
                  onClick={handleTransactionsView}
                >
                  TransactionsView
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                  onClick={handlePaymentRecords}
                >
                  Rent Payment Records
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faHandHoldingUsd} />}
                  onClick={handleSettlementsView}
                >
                  Settlements View
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faChartLine} />}
                  onClick={handleStatistics}
                >
                  Statistics
                </MenuItem>
              </SubMenu>
              <SubMenu
                title="Financial"
                icon={<FontAwesomeIcon icon={faChartBar} />}
              >
                <MenuItem
                  icon={<FontAwesomeIcon icon={faFileInvoice} />}
                  onClick={handleAccountsView}
                >
                  AccountsView
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                  onClick={handleWithdrawals}
                >
                  Withdraw
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faPiggyBank} />}
                  onClick={handleDeposits}
                >
                  Deposits
                </MenuItem>
              </SubMenu>
              <SubMenu title="Tenant" icon={<FontAwesomeIcon icon={faUsers} />}>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faComment} />}
                  onClick={handleComments}
                >
                  Comments
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faWrench} />}
                  onClick={handleMaintenance}
                >
                  Maintenance
                </MenuItem>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faDoorOpen} />}
                  onClick={handleVacating}
                >
                  Vacating
                </MenuItem>
              </SubMenu>
            </Menu>
          </SidebarContent>
          {/* Sidebar Footer */}
          <SidebarFooter>
            <div className="text-white text-center p-4">
              <p className="text-sm">© 2024 Reivax Softwares</p>
              <p className="text-sm">All Rights Reserved</p>
            </div>
          </SidebarFooter>
        </ProSidebar>

        <div className="flex-1 p-6">
          {/* Go back button */}
          <button
            onClick={() => navigate(-1)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back
          </button>

          {/* Property details */}
          {property && (
            <div>
              <h1 className="text-3xl font-semibold mb-4">{property.title}</h1>
              <div className="mb-4">
                <img
                  src={property.photo}
                  alt={property.title}
                  className="w-full sm:max-w-xs md:max-w-sm lg:max-w-md max-h-60 object-cover cursor-pointer rounded-md"
                />
                {/* <GlassMagnifier imageSrc={property.photo} alt={property.title} /> */}
              </div>
              <p className="mb-2">
                <strong>Location:</strong> {property.location}
              </p>
              <p className="mb-2">
                <strong>County:</strong> {property.county}
              </p>
              <p className="mb-2">
                <strong>Description:</strong> {property.description}
              </p>
              <p className="mb-2">
                <strong>Approval Status:</strong> {property.approvalstatus}
              </p>
            </div>
          )}
        </div>
      </div>
    </AuthGuard>
  );
};

export default PropertyPage;
