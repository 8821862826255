import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faMobileAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons"; // Import icons
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const DepositsPage = () => {
  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(true);
  const [amount, setAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [webUrl, setWebUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("ipay"); // State to handle the selected payment method

  const token = localStorage.getItem("token");
  const propertyId = localStorage.getItem("selectedPropertyId");
  const navigate = useNavigate();

  useEffect(() => {
    if (token && propertyId) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/balance/owner/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setBalance(
            response.data.response.length > 0
              ? response.data.response[0].balance
              : 0
          );
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
        });
    }
  }, [token, propertyId]);

  useEffect(() => {
    if (paymentMethod === "ipay") {
      setDepositAmount((amount * 0.98).toFixed(2)); // 2% charge for iPay
    } else if (paymentMethod === "mpesa") {
      setDepositAmount((amount * 0.99).toFixed(2)); // 1% charge for M-Pesa
    }
  }, [amount, paymentMethod]);

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const apiUrl =
      paymentMethod === "ipay"
        ? `https://backend.inyumba.co.ke/iNyumba/initiatepayment/${propertyId}`
        : `https://backend.inyumba.co.ke/iNyumba/user/mpesapayment/landlordtopupinitiatestk/${propertyId}`; // Adjust API endpoint for M-Pesa

    try {
      const response = await axios.post(
        apiUrl,
        {
          amount,
          phone_number: phoneNumber,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        if (paymentMethod === "ipay") {
          setWebUrl(response.data.response); // Only for iPay, set the webUrl
          toast.success("Payment initiated successfully!");
          setShowIframe(true); // Display iframe for iPay
        } else {
          if (
            response.data.response === "Kindly input your pin on mpesa pop up"
          ) {
            toast.info("Kindly input your pin on mpesa pop up");
            setShowIframe(false); // No iframe for M-Pesa
          } else {
            toast.success("M-Pesa payment initiated successfully!");
            setShowIframe(false); // No iframe for M-Pesa
          }
        }
      } else {
        toast.error(response.data.response || "Payment initiation failed!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.response);
    }
    setIsLoading(false);
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
    navigate("/landlord/deposits");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-semibold">Deposits</h1>
          <div className="flex items-center">
            {showBalance ? (
              <p className="mr-4">
                Balance: {balance !== null ? `Ksh ${balance}` : "0"}
              </p>
            ) : (
              <p className="mr-4">
                Balance: {balance !== null ? "*******" : "0"}
              </p>
            )}
            <FontAwesomeIcon
              icon={showBalance ? faEyeSlash : faEye}
              className="text-xl cursor-pointer"
              onClick={toggleShowBalance}
            />
          </div>
        </div>

        <div className="bg-white shadow-md rounded-md p-6">
          <h2 className="text-xl font-semibold mb-4">Deposits</h2>

          {/* Payment Method Selection */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Select Payment Method
            </label>
            <div className="flex">
              <button
                type="button"
                className={`${
                  paymentMethod === "ipay" ? "bg-blue-500" : "bg-gray-300"
                } text-white px-4 py-2 rounded-l`}
                onClick={() => setPaymentMethod("ipay")}
              >
                iPay
              </button>
              <button
                type="button"
                className={`${
                  paymentMethod === "mpesa" ? "bg-green-500" : "bg-gray-300"
                } text-white px-4 py-2 rounded-r`}
                onClick={() => setPaymentMethod("mpesa")}
              >
                M-Pesa
              </button>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {paymentMethod === "ipay" && (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Use 254 or 07 dont add +"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Amount
                  </label>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter amount"
                    required
                  />
                </div>
              </>
            )}

            {paymentMethod === "mpesa" && (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Use 254 or 07 dont add +"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Amount
                  </label>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter amount"
                    required
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Total Deposit Amount (after fees)
              </label>
              <p>Ksh {depositAmount}</p>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="white"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Deposit"
                )}
              </button>
            </div>
          </form>

          {showIframe && (
            <div className="mt-4">
              <iframe
                src={webUrl}
                width="100%"
                height="600"
                title="Payment Gateway"
              />
              <button
                className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleCloseIframe}
              >
                Close
              </button>
            </div>
          )}
        </div>
        {/* Conditionally render the iframe with close button */}
        {showIframe && webUrl && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
            <button
              onClick={handleCloseIframe}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Close
            </button>
            <iframe
              src={webUrl}
              className="w-screen h-screen border-none mx-auto"
              title="Payment Iframe"
            ></iframe>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default DepositsPage;
